import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'

import LottieShape from 'components/LottieShape'
import LoadingAnimation from 'components/Animations/LoadingAnimation'

const Loading = ({ height }) => {
  const LoadingContainer = styled.div`
    display: flex;
    justify-content: center;
    height: ${height};
    margin-top: 10vh;
  `

  return (
    <LoadingContainer>
      <LottieShape animationData={LoadingAnimation} />
    </LoadingContainer>
  )
}

Loading.defaultProps = {
  height: '100vh',
}

Loading.propTypes = {
  height: PropTypes.string,
}

export default Loading
