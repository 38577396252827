import React, { useState, useEffect, useContext } from 'react'
import { Link } from 'gatsby'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { Global } from '@emotion/react'
import styled from '@emotion/styled'

import SubscriptionContext from 'context/subscription/SubscriptionContext'
import {
  StripeCheckoutRedirectContainer,
  StripeCheckoutContentContainer,
  StripeCheckoutRedirectDescription,
  StripeCheckoutRedirectButton,
  ShapeContainer,
} from 'components/StripeCheckout'
import Loading from 'components/Loading'
import LottieShape from 'components/LottieShape'
import AbstractPaymentAnimation from 'components/Animations/AbstractPaymentAnimation'
import GlobalStyles from 'styles/GlobalStyles'

const InputBox = styled.input`
  width: 300px;
  padding: 12px 20px;
  margin: 8px 0;
  box-sizing: border-box;
  background: #dbf8ff;
  margin-bottom: 3em;
`

const Coupon = ({ location }) => {
  let queryStringParams
  let sourceParam
  let tokenParam

  if (typeof window !== 'undefined') {
    queryStringParams = new URLSearchParams(window.location.search)
    sourceParam = queryStringParams.get('source')
    tokenParam = queryStringParams.get('token')
  }

  const SOURCE_WEB = 'WEB'
  const SOURCE_FW_APP = 'FWAPP'

  const SOURCE = sourceParam === 'FWAPP' ? SOURCE_FW_APP : SOURCE_WEB

  const ONE_HUNDRED_PERCENT_OFF = 100
  const [couponCodeText, setCouponCodeText] = useState('')
  const {
    coupon,
    subscriptionStateLoading,
    fetchStripeCoupon,
    createStripeSubscription,
    createStripeSubscriptionSession,
    fetchAvailableSitePlans,
    subscriptions,
  } = useContext(SubscriptionContext)
  const { t, ready } = useTranslation('couponPage')

  let subscriptionName = null
  // get the subscription name for web users
  if (SOURCE === SOURCE_WEB) {
    if (location && location.state && location.state.subscriptionName) {
      subscriptionName = location.state.subscriptionName
    }
  }
  // set a subscription name for FW APP users
  else if (SOURCE === SOURCE_FW_APP) {
    subscriptionName = 'FluentWorlds Monthly'
  }
  // default to null
  else {
    subscriptionName = null
  }

  useEffect(() => {
    if (subscriptions) {
      fetchAvailableSitePlans()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (coupon && coupon.valid && subscriptions) {
      // if the coupon is 100 percent off, silently create subscription
      // otherwise if partial discount, redirect to stripe checkout
      if (coupon.percentOff === ONE_HUNDRED_PERCENT_OFF) {
        createStripeSubscription(
          subscriptionName,
          couponCodeText.trim(),
          SOURCE_WEB,
          null,
          null,
          tokenParam,
        )
      } else {
        createStripeSubscriptionSession(
          subscriptionName,
          couponCodeText.trim(),
          0,
          tokenParam,
        )
      }
    } else if (coupon && !coupon.valid) {
      window.alert('Please enter in a valid coupon')
      return
    }
    // eslint-disable-next-line
  }, [coupon, subscriptions])

  const handleInputChange = (e) => {
    setCouponCodeText(e.currentTarget.value)
  }

  const submitCoupon = async () => {
    if (couponCodeText === '') {
      window.alert('Please enter in a valid coupon')
      return
    }

    try {
      if (subscriptionName !== null) {
        fetchStripeCoupon(couponCodeText.trim(), tokenParam)
      } else {
        window.alert(
          'An error has occurred, please go back to the account page and try again.',
        )
      }
    } catch (err) {
      throw new Error(err)
    }
  }

  const showLoading = subscriptionStateLoading || !ready

  return (
    <>
      <Helmet>
        <title>FluentWorlds</title>
      </Helmet>
      <Global styles={GlobalStyles} />
      {showLoading ? (
        <Loading />
      ) : (
        <>
          <StripeCheckoutRedirectContainer>
            <ShapeContainer>
              <LottieShape
                size={500}
                animationData={AbstractPaymentAnimation}
              />
            </ShapeContainer>
            <StripeCheckoutContentContainer>
              <StripeCheckoutRedirectDescription>
                {t('prompt')} {subscriptionName}
              </StripeCheckoutRedirectDescription>
              <InputBox onChange={handleInputChange} />
              <StripeCheckoutRedirectButton onClick={() => submitCoupon()}>
                {t('submit')}
              </StripeCheckoutRedirectButton>
              <Link
                css={`
                  width: 300px;
                  margin-top: 3em;
                `}
                to="/account"
              >
                <StripeCheckoutRedirectButton>
                  {t('goToAccount')}
                </StripeCheckoutRedirectButton>
              </Link>
            </StripeCheckoutContentContainer>
          </StripeCheckoutRedirectContainer>
        </>
      )}
    </>
  )
}

export default Coupon
